.spinner {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    padding-bottom: 5px;
}

.input-search {
    width: 250px
}

.card-box {
    width: 70%;
}

.card-section {
    padding: 0;
}

[class*="sc-egiyK"] {
    font-weight: bold;
    font-size: 13px;
}