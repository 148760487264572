* {
  margin: 0;
  padding: 0;
}

.datepicker {
  height: 35px;
  width: 200px;
  font-size: 15px;
  padding-left: 13px;
}

.chat {
  background: transparent;
  border: none;
  font-weight: 600;
  color: #2E61DE;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
}
